<template>
  <div>
    <b-container fluid class="form thank-you pt-5 pb-5 gray-theme uti-component">
      <b-container class="thankyouform pt-2">
        <div class="heading">
          <div>
            <h1 v-html="inputData.h1"></h1>
          </div>
          <div class="h1copy" v-html="inputData.h1Copy"></div>
        </div>

        <div class="Form" v-if="this.$store.state.formData.IsComplete == false && this.inputData.isComplete != true">
          <b-row>
            <b-col md>
              <div class="personalinformation">
                <b-row class="pt-2">
                  <b-col>
                    <h5 class="text-muted mb-0 pb-0 font-weight-light">More Personal Information</h5>
                    <hr />
                  </b-col>
                </b-row>

                <!--DoB-->
                <div class="dob">
                  <label for="dob-input">Date of Birth</label>

                  <b-input-group class="mb-3" :class="{ hasError: $v.$error }">
                    <b-form-input
                      id="dateOfBirth"
                      name="date-of-birth"
                      v-model="form.DateOfBirth"
                      type="date"
                      placeholder="MM-DD-YYYY"
                      :date-format-options="{
                        month: 'numeric',
                        day: 'numeric',
                        year: 'numeric'
                      }"
                      autocomplete="off"
                      :class="{ isInvalidInput: $v.form.DateOfBirth.$error }"
                      @input="dobChanged()"
                    ></b-form-input>
                  </b-input-group>
                </div>
              </div>
            </b-col>
            <!--HS Edu Info-->
            <b-col md v-if="this.$store.state.formData.Age < 20" class="highschoolinformation">
              <!--HS Edu Type-->
              <div class="hs-edu-wrapper pt-2" v-if="this.$store.state.formData.Education == null">
                <b-row>
                  <b-col>
                    <h5 class="text-muted mb-0 pb-0 font-weight-light">Education Type</h5>
                    <hr />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group
                      id="HSEduTypeWrapper"
                      label="What type of Diploma or Certificate will/did you obtain?"
                      label-for="selHSEduType"
                      label-class="text-left"
                    >
                      <b-form-select
                        aria-describedby="eduSelect"
                        class="chosen-select"
                        id="selHSEduType"
                        name="highschool-education"
                        required=""
                        data-abide-ignore="true"
                        v-model="HighSchoolEduType"
                        :class="{
                          isInvalidInput: $v.form.HighSchoolEduType.$error
                        }"
                      >
                        <b-form-select-option value="">Select HS Education Type</b-form-select-option>
                        <b-form-select-option value="HS Grad">High School Diploma</b-form-select-option>
                        <b-form-select-option value="GED">GED</b-form-select-option>
                        <b-form-select-option value="Home School">Home School Diploma</b-form-select-option>
                        <b-form-select-option value="HS Grad">None, I'm in High School</b-form-select-option>
                        <b-form-select-option value="GED">None, I'm getting my GED</b-form-select-option>
                        <b-form-select-option value="Home School">None, I'm being Home Schooled</b-form-select-option>
                        <b-form-select-option value="No Equivalent">None</b-form-select-option>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>

              <!--HS Edu Details-->
              <div v-if="this.HighSchoolEduType == 'HS Grad'">
                <b-row class="py-2 pt-2">
                  <b-col class="col-12">
                    <h5 class="text-muted mb-0 pb-0 font-weight-light">Current or Last High School</h5>
                    <hr />
                  </b-col>
                </b-row>

                <b-row>
                  <b-col class="col-12">
                    <b-form-group id="HSState" label="State" label-for="selHSState" label-class="text-left">
                      <b-form-select
                        id="selHSState"
                        name="highschool-state"
                        v-model="HighSchoolState"
                        :class="{
                          isInvalidInput: $v.form.HighSchoolState.$error
                        }"
                      >
                        <b-form-select-option value="">Select State</b-form-select-option>

                        <b-form-select-option v-for="option in inputData.State" :value="option.Code" :key="option.Code">{{
                          option.State
                        }}</b-form-select-option>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col class="col-12">
                    <b-form-group id="HSCity" label="City" label-for="input-3" label-class="text-left">
                      <b-form-select
                        id="selHSCity"
                        name="highschool-city"
                        v-model="HighSchoolCity"
                        disabled
                        :class="{ isInvalidInput: $v.form.HighSchoolCity.$error }"
                      >
                        <b-form-select-option value="">Select City</b-form-select-option>

                        <b-form-select-option v-for="option in inputData.HighSchoolCity" :value="option" :key="option">{{
                          option
                        }}</b-form-select-option>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col class="col-12">
                    <b-form-group id="HighSchool" label="HighSchool" label-for="input-4" label-class="text-left">
                      <b-form-select
                        id="selHighSchool"
                        name="highschool-id"
                        v-model="HighSchoolID"
                        disabled
                        :class="{ isInvalidInput: $v.form.HighSchoolID.$error }"
                      >
                        <b-form-select-option value="">Select High School</b-form-select-option>

                        <b-form-select-option v-for="option in inputData.HighSchools" :value="option.HighSchoolId" :key="option.HighSchoolId">{{
                          option.Name
                        }}</b-form-select-option>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
            </b-col>

            <!--Adult Edu Info-->
            <b-col class="educationalbackground-wrapper" md v-if="this.$store.state.formData.Age == null || this.$store.state.formData.Age >= 20">
              <b-row>
                <div class="personalinformation col-12 py-2 pt-2">
                  <b-row>
                    <b-col>
                      <h5 class="text-muted mb-0 pb-0 font-weight-light">Education Information</h5>
                      <hr />
                    </b-col>
                  </b-row>
                </div>
                <div class="pl-3">
                  <label>Do you have or are you getting, a high school diploma</label>
                  <b-row class="pl-2">
                    <b-col class="ml-7">
                      <b-form-radio-group
                        id="HSEduRadios"
                        label="highschool education radios"
                        v-model="form.AdultEducationHS"
                        name="highshool-education-radio-group"
                        :class="{ isInvalidInput: $v.form.AdultEducationHS.$error }"
                        @input="AdultEducationHSChange($event)"
                      >
                        <b-form-radio class="ml-2" value="HS Grad">Yes</b-form-radio>
                        <b-form-radio class="ml-2" value="no">No</b-form-radio>
                      </b-form-radio-group>
                    </b-col>
                  </b-row>
                </div>

                <br />

                <div class="pl-3 pt-1 pb-2" v-if="this.form.AdultEducationHS == `no`">
                  <label>Do you have or are you in the process of getting a GED</label>
                  <b-row class="pl-4">
                    <b-form-radio-group
                      id="GEDEduRadios"
                      label="GED education radios"
                      v-model="form.AdultEducationGED"
                      name="GED-education-radio-group"
                      :class="{ isInvalidInput: $v.form.AdultEducationGED.$error }"
                    >
                      <b-form-radio class="ml-2" name="GED" value="GED">Yes</b-form-radio>
                      <b-form-radio class="ml-2" name="GED" value="No Equivalent">No</b-form-radio>
                    </b-form-radio-group>
                  </b-row>
                </div>
              </b-row>
            </b-col>
            <!--Military Info-->
            <b-col md v-if="this.$store.state.formData.IsMilitary == true">
              <div class="militaryinformation">
                <b-row class="col-12 py-2 pt-2">
                  <h5 class="text-muted mb-0 pb-0 font-weight-light">Military Duty Station Details</h5>
                  <hr />
                </b-row>
                <div v-show="inputData.isOverSeas == 'false'">
                  <b-form-group id="MilState" label="State" label-for="input-5" label-class="text-left">
                    <b-form-select
                      id="selMilState"
                      name="military-state"
                      v-model="MilitaryState"
                      :class="{ isInvalidInput: $v.form.MilitaryState.$error }"
                    >
                      <b-form-select-option value="">Please Select a State</b-form-select-option>

                      <b-form-select-option v-for="option in inputData.State" :value="option.Code" :key="option.Code">{{
                        option.State
                      }}</b-form-select-option>
                    </b-form-select>
                  </b-form-group>
                </div>

                <div v-show="inputData.isOverSeas == 'false'">
                  <b-form-group id="DutyStation" label="Duty Station" label-for="input-6" label-class="text-left">
                    <b-form-select
                      id="selDutyStation"
                      name="military-duty-station"
                      v-model="MilitaryID"
                      disabled
                      :class="{ isInvalidInput: $v.form.MilitaryID.$error }"
                    >
                      <b-form-select-option value="">Please Select a Duty Station</b-form-select-option>

                      <b-form-select-option v-for="option in inputData.militaryBases" :value="option.MilitaryBaseId" :key="option.MilitaryBaseId">{{
                        option.Name
                      }}</b-form-select-option>
                    </b-form-select>
                  </b-form-group>
                </div>
                <div v-show="inputData.isOverSeas == 'true'">
                  <b-form-group id="MilCountry" label="Country" label-for="input-6" label-class="text-left">
                    <b-form-select
                      id="selMilCountry"
                      name="military-country"
                      v-model="MilitaryBaseOverSeas"
                      :class="{ isInvalidInput: $v.form.MilitaryBaseOverSeas.$error }"
                    >
                      <b-form-select-option value="">Select a Country</b-form-select-option>
                      <b-form-select-option v-for="option in getCOUNTRIES()" :value="option.code" :key="option.code">{{
                        option.country
                      }}</b-form-select-option>
                    </b-form-select>
                  </b-form-group>
                </div>
                <b-form-checkbox class="pb-4" id="overseas" v-model="inputData.isOverSeas" name="MilOverseas" value="true" unchecked-value="false">
                  Military Base Overseas?
                </b-form-checkbox>
              </div>
            </b-col>
          </b-row>

          <!--ty button-->
          <b-row align-h="center">
            <b-col cols="12" md="3">
              <b-btn block variant="primary" class="submit mb-3" size="lg" v-on:click="submit" :v="$v">Submit</b-btn>
            </b-col>
          </b-row>
        </div>

        <b-row>
          <b-col>
            <div v-if="this.$store.state.formData.IsComplete == true || this.inputData.isComplete == true" class="completed">
              <b-row align-h="center">
                <b-col class="text-center">
                  <hr />

                  <h2>Make a Smart Decision For Your Future</h2>

                  <p>We're here to help. Here are some links to important information about our schools and programs.</p>
                </b-col>
              </b-row>
              <b-row align-h="center">
                <b-col>
                  <img
                    src="https://cdn.uti.edu/PMLP-rev.uti.edu/image-uti-students-walking-down-hallway.png"
                    alt="uti students walking down hallway"
                    fluid
                  /><br />
                  <a href="https://www.uti.edu/locations/campus-tours?hf=1" class="button" aria-label="Link to ">Schedule a Tour</a>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-container>
    <b-container fluid class="footer pl-0 pr-0">
      <b-container>
        <b-row class="pb-0 mb-0">
          <Disclaimer :data="disclaimer" />
        </b-row>
      </b-container>
    </b-container>
  </div>
</template>

<script>
import Disclaimer from "@/components/Footer/Disclaimer";
//axios will be used to get highschool and military fields
import axios from "axios";
//import getLGAPI from "../../globalFunctions/globalFunctions";
import { required, helpers, requiredIf } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
const isValidDOB = helpers.regex("isValidDOB", /^\d{4}-\d{2}-\d{2}$/);
export default {
  name: "ThankYou",
  components: {
    Disclaimer,
    ...mapGetters(["COUNTRIES"])
    //About
  },
  // props: ["heroImg","heroData"],
  data() {
    return {
      disclaimer: {},
      inputData: {
        //age: this.$store.state.formData.Age,
        isComplete: false,
        //isMilitary: this.$store.state.formData.IsMilitary,
        isOverSeas: "false",
        militaryBaseOverSeas: "",
        militaryBases: [],
        State: [],
        HighSchoolCity: [],
        HighSchools: [],
        h1: "LAST STEP!",

        h1Copy: "Just a few more questions & we’ll pair you with the right Admissions Rep."
      },
      form: {
        DateOfBirth: "",
        AdultEducationGED: "",
        AdultEducationHS: "",
        HighSchoolEduType: "",
        HighSchoolState: "",
        HighSchoolCity: "",
        HighSchoolID: "",
        MilitaryBaseOverSeas: "",
        //_highSchoolGrad: "",
        MilitaryState: "",
        MilitaryID: "",
        Education: "",
        EducationHS: "",
        Comments: ""
      },
    };
  },
  //This lifeCycle is used so a user cannot directly navigate to the thank you page. It will redirect them to the root/homepage.
  //beforeCreate() {
  //if (this.$store.state.formData.Age == null) {
  // this.$router.push({ path: "/" });
  //}
  //},
  beforeMount() {
    this.serverDataDisclaimer();
  },
  mounted() {

    this.virtualPageview();
    if (this.$store.state.formData.Age == null) {
      this.$router.push({ path: "/" });
    }
    //console.log(this.$store.state.formData);
    //  this.getMilitaryBases();
    this.getStates();
    if (this.$store.state.formData.Education != null) {
      let el = document.getElementById("HSEduType");
      el.removeAttribute("disabled");
    }
    //this.form.isMilitary = this.$store.state.formData.isMilitary;
    //console.log("isMil: " + this.$store.state.formData.IsMilitary);
    window.scrollTo(0, 0);
    //setTimeout(function () { timedComplete(); }, 5 * 60 * 1000);
    this.timedComplete(5 * 60 * 1000);
   
  },
  validations: {
    form: {
      DateOfBirth: {
        required,
        isValidDOB
      },
      HighSchoolEduType: {
        required: requiredIf(function () {
          let condition = this.$store.state.formData.Age < 20 && this.$store.state.formData.Education == null;
          return condition;
        })
      },
      HighSchoolState: {
        required: requiredIf(function () {
          let condition =
            this.$store.state.formData.Age < 20 && (this.$store.state.formData.Education == "HS Grad" || this.form.HighSchoolEduType == "HS Grad");
          return condition;
        })
      },
      HighSchoolCity: {
        required: requiredIf(function () {
          let condition = this.$store.state.formData.Age < 20 && this.form.HighSchoolState != "";
          return condition;
        })
      },
      HighSchoolID: {
        required: requiredIf(function () {
          let condition = this.$store.state.formData.Age < 20 && this.form.HighSchoolState != "" && this.form.HighSchoolCity != "";
          return condition;
        })
      },
      AdultEducationHS: {
        required: requiredIf(function () {
          let condition = this.$store.state.formData.Age >= 20 && this.$store.state.formData.Education == null;
          return condition;
        })
      },
      AdultEducationGED: {
        required: requiredIf(function () {
          let condition = this.$store.state.formData.Age >= 20 && this.form.EducationHS == "no";
          return condition;
        })
      },
      MilitaryState: {
        required: requiredIf(function () {
          let condition =
            this.$store.state.formData.Age >= 18 && this.$store.state.formData.IsMilitary == true && this.inputData.isOverSeas != "true";
          return condition;
        })
      },
      MilitaryID: {
        required: requiredIf(function () {
          let condition =
            this.$store.state.formData.Age >= 18 &&
            this.$store.state.formData.IsMilitary == "true" &&
            this.form.MilitaryState != "" &&
            this.inputData.isOverSeas != "true";
          return condition;
        })
      },
      MilitaryBaseOverSeas: {
        required: requiredIf(function () {
          let condition = this.$store.state.formData.Age >= 18 && this.inputData.isOverSeas == `true`;
          return condition;
        })
      }
    }
  },
  computed: {
    HighSchoolEduType: {
      get() {
        return this.form.HighSchoolEduType;
      },
      set(value) {
        this.$v.form.HighSchoolEduType.$touch();
        this.form.HighSchoolEduType = value;
        //let el = document.getElementById("selHSState");
        //el.removeAttribute("disabled");
      }
    },
    HighSchoolState: {
      get() {
        return this.form.HighSchoolState;
      },
      set(value) {
        this.$v.form.HighSchoolState.$touch();
        this.form.HighSchoolState = value;
        this.getHighSchoolCity();
        //this.$store.dispatch("SET_HIGHSCHOOLSTATE_ACTION", value);
        let highSchoolElement = document.getElementById("selHSCity");
        highSchoolElement.removeAttribute("disabled");
      }
    },
    HighSchoolCity: {
      get() {
        return this.form.HighSchoolCity;
      },
      set(value) {
        this.$v.form.HighSchoolCity.$touch();
        this.form.HighSchoolCity = value;
        this.getHighSchools();
        //this.$store.dispatch("SET_HIGHSCHOOLCITY_ACTION", value);
        let highSchool = document.getElementById("selHighSchool");
        highSchool.removeAttribute("disabled");
      }
    },
    HighSchoolID: {
      get() {
        return this.form.HighSchoolID;
      },
      set(value) {
        this.$v.form.HighSchoolID.$touch();
        this.form.HighSchoolID = value;
        //this.$store.dispatch("SET_HIGHSCHOOLID_ACTION", value);
        /// If HS not listed...
        /// Show write in text box
      }
    },
    MilitaryState: {
      get() {
        return this.form.MilitaryState;
      },
      set(value) {
        this.$v.form.MilitaryState.$touch();
        this.form.MilitaryState = value;
        this.getMilitaryBases();
        //this.$store.dispatch("SET_MILITARYSTATE_ACTION", value);
        let militaryElement = document.getElementById("selDutyStation");
        militaryElement.removeAttribute("disabled");
        this.form.comments = "";
      }
    },
    MilitaryID: {
      get() {
        return this.form.MilitaryID;
      },
      set(value) {
        this.$v.form.MilitaryID.$touch();
        this.form.MilitaryID = value;
        this.form.comments = "";
        //this.$store.dispatch("SET_MILITARYID_ACTION", value);
        /// If Installation not listed...
        /// Show write in box
      }
    },
    MilitaryBaseOverSeas: {
      get() {
        return this.form.MilitaryBaseOverSeas;
      },
      set(value) {
        this.$v.form.MilitaryBaseOverSeas.$touch();
        this.form.MilitaryBaseOverSeas = value;
        this.form.MilitaryID = "";
        this.form.MilitaryState = "";
        this.form.Comments = `Military Base Overseas - Military Base Country:${value}`;
      }
    }
  },
  methods: {
    serverDataDisclaimer: function () {
      // console.log(this.$store.state.serverData);
      let disclaimerArray = this.$store.state.serverData.data.find((element) => element.type == "disclaimer");
      this.disclaimer = disclaimerArray.data;
      // console.log(disclaimerArray);
    },
     timedComplete(arg){
       var timeout;
       try{
         clearTimeout(timeout);
         timeout = setTimeout( () => {
          // console.log('Timeout pop')
        this.inputData.isComplete = true;
        this.inputData.h1 = "Thank You!<br />What's Next?";
        this.inputData.h1Copy =
          "We appreciate your interest. One of our Admissions Representatives will contact you soon to answer questions and provide additional information about our programs, student services and more.";
        }, arg);
       } catch (error){
         console.error(error);
       }
      
    },
      //practicing with stuff
    // virtualPageview(){
      
    //     window.dataLayer.push({
    //       event: 'tyPageView', //trigger in GTM
    //       pageUrl: "https://rev.uti.edu/thankyou",
    //       //pageTitle: title
    //     })
    //     console.log(`this is it`)
    // },
    virtualPageview() {
      this.$store.dispatch("DATA_LAYER_TY_PAGEVIEW_ACTION");
    },

    getMilitaryBases() {
      axios
        .get(`https://leadgateway.uti.edu/api/militarybases/?state=${this.form.MilitaryState}`)
        .then((response) => {
          this.inputData.militaryBases = response.data;
          // console.log(`state currently equals,`, this.inputData.militaryBases);
          //console.log(response);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
        .then(function () {
          // always executed
          //  console.log("final then");
        });
    },
    getStates() {
      axios
        //.get(`https://utileadgateway.uti.edu/api/highschoolstate`)
        .get(`https://leadgateway.uti.edu/api/highschoolstate`)
        //.get(getLGAPI() + "highschoolstate")
        .then((response) => {
          this.inputData.State = response.data;
          // console.log(`state currently equals,`, this.inputData.State);
          //console.log(response);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
        .then(function () {
          // always executed
          // console.log("final then");
        });
    },
    getHighSchoolCity() {
      axios
        // .get(
        //   `https://utileadgateway.uti.edu/api/highschoolcity?state=${this.form.HighSchoolState}`
        // )
        .get(`https://leadgateway.uti.edu/api/highschoolcity?state=${this.form.HighSchoolState}`)
        //.get(getLGAPI() + `highschoolcity?state=${this.form.HighSchoolState}`)
        .then((response) => {
          this.inputData.HighSchoolCity = response.data;
          //  console.log(`state currently equals,`, this.inputData.HighSchoolCity);
          //console.log(response);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
        .then(function () {
          // always executed
          // console.log("final then");
        });
    },
    getHighSchools() {
      axios
        // .get(
        //   `https://utileadgateway.uti.edu/api/highschools?state=${this.form.HighSchoolState}&city=${this.form.HighSchoolCity}`
        // )
        .get(`https://leadgateway.uti.edu/api/highschools?state=${this.form.HighSchoolState}&city=${this.form.HighSchoolCity}`)
        //.get(getLGAPI() + `highschools?state=${this.form.HighSchoolState}&city=${this.form.HighSchoolCity}`)
        .then((response) => {
          this.inputData.HighSchools = response.data;
          // console.log(`state currently equals,`, this.inputData.HighSchool);
          //console.log(response);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
        .then(function () {
          // always executed
          //  console.log("final then");
        });
    },
    getCOUNTRIES() {
      let countries = this.$store.getters.COUNTRIES;
      countries = countries.filter((country) => country.code != "US");
      return countries;
    },
    AdultEducationHSChange(event) {
      if (event == "HS Grad") {
        this.form.AdultEducationGED = "";
      }
    },

    dobChanged() {
      this.$v.form.DateOfBirth.$touch();
      //this.$store.dispatch("SUBMIT_DATA_LAYER_FORM_START_ACTION");
    },

    submit() {
      this.$v.$touch();
      //console.log(this.form.Comments);
      //console.log(new Date(new Date().toString().split('GMT')[0]+' UTC').toISOString().split('.')[0])
      //console.log(new Date().toLocaleDateString());
      /// Form-Submit DL Event
      window.dataLayer.push({
        event: "form-submit",
        formId: "UTIFORM035",
        age: this.form.Age,
        areaOfInterest: this.form.AOI,
        //'educationLevel': dataPayload.Education != undefined ? dataPayload.Education : "",
        //'email': dataPayload.EmailAddresses[0].Addresses,
        //'errorMessage': errMsg,
        //'formName': 'Paid Media Landing Page' + formName,
        formName: "PMLPv2 Thank You Form",
        formType: "thank you",
        //'formValidationStatus': "",
        inquiryDate: new Date(new Date().toString().split('GMT')[0]+' UTC').toISOString().split('.')[0],
        isMilitary: this.form.isMilitary,
        leadId: this.$store.state.formData.inqId,
        //'numberOfInquiries': inqcount != undefined ? inqcount : "",
        referralPage: document.referrer
        //'userAgent': dataManager.userAgent().UserAgent === "" ? undefined : dataManager.userAgent().UserAgent
      });

      if (this.$v.$invalid) {
        console.log("error");
      } else {
        //  console.log("submit valid!");
        this.$store.dispatch("SET_TYFORM_DATA_ACTION", this.form);
        this.inputData.h1 = "Thank You!<br />What's Next?";
        this.inputData.h1Copy =
          "We appreciate your interest. One of our Admissions Representatives will contact you soon to answer questions and provide additional information about our programs, student services and more.";
        window.scrollTo(0, 0);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
}
.heading {
  text-align: center;
  padding-bottom: 15px;
  margin-top: 15px;
}
.h1copy {
  font-size: 18px;
  line-height: 30px;
}
h1 {
  font-family: Tungsten-Semibold;
  font-size: 46px;
  line-height: 46px;
  margin-bottom: 10px;
  letter-spacing: 1px;
}
.submit {
  width: 100%;
  margin-top: 1rem;
}
.completed {
  text-align: center;
  padding-bottom: 10px;
  //transition: ;
}
.completed img {
  max-width: inherit;
}
.completed .button {
  background-color: transparent;
  border: 1px solid #000;
  border-radius: 0;
  color: #000;
  cursor: pointer;
  display: inline-block;
  // font-size: 0.8888888889rem;
  font-weight: 500;
  line-height: 1.4;
  margin: 1.5rem 0 0 0;
  padding: 0.9rem;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  vertical-align: middle;
  -webkit-appearance: none;
  z-index: 1;
}
.thank-you {
  background-color: #d9d9d9;
}
.thank-you .thankyouform {
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #fff;
  border: 1px solid #a9a9a9;
}
.footer {
  background: linear-gradient(180deg, #003852 0, #0086d2 100%);
}
</style>
